import React from 'react';
import './Skills.css';
import nodejsImage from '../../assets/images/nodejs.svg';
import mysqlImage from '../../assets/images/mysql.svg';
import javaScriptImage from '../../assets/images/javascript.svg';
import djangoImage from '../../assets/images/django.svg';
import htmlImage from '../../assets/images/html-5.svg'
import cssImage from '../../assets/images/css-3.svg'
import seleniumImage from '../../assets/images/selenium.svg';
import pythonImage from '../../assets/images/python.svg';
import mongoDBImage from '../../assets/images/mongodb.svg';
import expressImage from '../../assets/images/express.svg';
import reactJSImage from '../../assets/images/react.svg'
import gitIconImage from '../../assets/images/git-icon.svg'
import awsGlueImage from '../../assets/images/aws-glue.svg'
import awsLambdaImage from '../../assets/images/aws-lambda.svg'



function Skills() {
  return (
    <section id="skills">
      <ul>
        <li>
          <img src={nodejsImage} alt="NodeJS Icon" className="icon"/>
          <span>NodeJS</span>
        </li>
        <li>
          <img src={mysqlImage} alt="MySQL Icon" className="icon"/>
          <span>MySQL</span>
        </li>
        <li>
          <img src={javaScriptImage} alt="JavaScriptImage Icon" className="icon"/>
          <span>JavaScript</span>
        </li>
        <li>
          <img src={htmlImage} alt="htmlImage Icon" className="icon"/>
          <span>HTML</span>
        </li>
        <li>
          <img src={cssImage} alt="CSSImage Icon" className="icon"/>
          <span>CSS</span>
        </li>
        <li>
          <img src={reactJSImage} alt="ReactJSImage Icon" className="icon"/>
          <span>ReactJS</span>
        </li>
        <li>
          <img src={djangoImage} alt="Django Icon" className="icon"/>
          <span>Django</span>
        </li>
        <li>
          <img src={seleniumImage} alt="Selenium Icon" className="icon"/>
          <span>Selenium</span>
        </li>
        <li>
          <img src={pythonImage} alt="Python Icon" className="icon"/>
          <span>Python</span>
        </li>
        <li>
          <img src={mongoDBImage} alt="MongoDB Icon" className="icon filter-icon"/>
          <span>MongoDB</span>
        </li>
        <li>
          <img src={expressImage} alt="express Icon" className="icon filter-icon"/>
          <span>Express</span>
        </li>
        <li>
          <img src={gitIconImage} alt="gitImage Icon" className="icon"/>
          <span>Git</span>
        </li>
        <li>
          <img src={awsGlueImage} alt="AWSGlueImage Icon" className="icon"/>
          <span>AWS Glue</span>
        </li>
        <li>
          <img src={awsLambdaImage} alt="AWSLambdaImage Icon" className="icon"/>
          <span>AWS Lambda</span>
        </li>
      </ul>
    </section>
  );
}

export default Skills;
