import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import './Header.css'
import instagramImage from '../../assets/images/instagram.svg';
import linkedinImage from '../../assets/images/linkedin-icon.svg';
import githubImage from '../../assets/images/github-icon.svg';
function Header() {


  return (
    <header>
      <nav>
        <ul>
          <li><Link activeClass="active" to="about" spy={true} smooth={true} offset={-300} duration={500} >About</Link></li>
          <li><Link activeClass="active" to="projects" spy={true} smooth={true} offset={-300} duration={500} >Projects</Link></li>
          <li><Link activeClass="active" to="skills" spy={true}  smooth={true} offset={-200} duration={500} >Skills</Link></li>
          <li><Link activeClass="active" to="experience" spy={true}  smooth={true} offset={-200} duration={500} >Experience</Link></li>
          <li><Link activeClass="active" to="contact" spy={true}  smooth={true} offset={-200} duration={500}>Contact</Link></li>
        </ul>
      </nav>
      <span className="socials">
      <a href="https://www.instagram.com/pencilandstrokes/" target="_blank" and rel="noopener noreferrer"><img src={instagramImage} alt="Instagram Icon" className="icon header-icon" title="Instagram"/></a>
      <a href="https://www.linkedin.com/in/kumarvibho/" target="_blank" and rel="noopener noreferrer"><img src={linkedinImage} alt="LinkedIn Icon" className="icon header-icon" title="LinkedIn"/></a>
      <a href="https://github.com/1vibho3" target="_blank" and rel="noopener noreferrer"><img src={githubImage} alt="Github Icon" className="icon header-icon" title="Github"/></a>
      </span>
    </header>
  );
}

export default Header;
