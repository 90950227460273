import React, { useState } from 'react';
import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import Experience from './components/Experience/Experience';
import ContactForm from './components/ContactForm/ContactForm';
import ToggleSwitch from './components/ToggleSwitch/ToggleSwitch';
import './styles/App.css';

function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const handleToggle = () => {
    setIsDarkTheme(!isDarkTheme);
  };
  return (
    <div className={`App ${isDarkTheme ? 'dark' : 'light'}`}>
      <div className="sidebar">
        <Header />
      </div>
      <div className="content-container">
        <main>
          <div>
        <ToggleSwitch className="toggleSwitch" onToggle={handleToggle} />
         <About />
         </div>
          <Projects />
          <Skills />
          <Experience />
          <ContactForm />
        </main>
      </div>
    </div>
  );
}

export default App;
