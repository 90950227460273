import React from 'react';
import LeetCodeStatistics from '../LeetCodeStats/LeetCodeStats';
import './About.css'

function About() {
  return (
    <section id="about" >
      <h1>Kumar Vibho</h1>
      <p>I am a <span>Computer Science</span> master's student at the <span>University of Illinois at Urbana-Champaign</span> who loves logic, problem-solving and writing code. When I am not working, I enjoy solving LeetCode problems, playing soccer, watching F1 and sketching.</p>
      <p>I am currently seeking full-time opportunities where I can leverage my expertise and enthusiasm to contribute to cutting-edge projects. Let's connect and create something remarkable together!</p>
      <div className="additional-details">
        <div id="education">
          <h3>Relevant Coursework</h3>
          <ul>
            <li>Database Systems</li>
            <li>Cloud Computing Applications</li>
            <li>Human Centerd Machine Learning</li>
            <li>Distributed Systems</li>
          </ul>
        </div>
        <LeetCodeStatistics />
      </div>
      
    </section>
  );
}

export default About;
