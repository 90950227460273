import React from 'react';
import './ToggleSwitch.css';

function ToggleSwitch({ onToggle }) {
  return (
    <div className="toggle-switch">
      <input type="checkbox" id="toggle" onChange={onToggle} />
      <label htmlFor="toggle" className="switch"></label>
    </div>
  );
}

export default ToggleSwitch;