import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './LeetCodeStats.css';

function LeetCodeStatistics() {

    const [stats, setStats] = useState([]);
    
    useEffect(() => {
        const fetchStats = async () => {
            try{
                const response = await axios.get('https://leetcode-stats-api.herokuapp.com/DesertCode');
                console.log(response.data);
                setStats(response.data);
            }catch(error){
                console.log('Error fetching stats', error);
            }
        };
        fetchStats();
        
    }, []);



    return (
        <div className="statistics">
            
            <div className="total">
                <p class="title">LeetCode</p>
                <p class="stats">{stats.totalSolved}/{stats.totalQuestions}</p>
            </div>
            <div className="difficulty">
                    <div className="easy solved">
                        <label>Easy</label>
                        <div className="progress-bar-container">
                            <div className="progress-bar" style={{ width: `${(stats.easySolved / stats.totalEasy) * 100}%` }}></div>
                            <div className="progress-label">
                                {stats.easySolved} / {stats.totalEasy}
                            </div>
                        </div>
                    </div>
            
                    <div className="medium solved">
                        <label>Medium</label>
                        <div className="progress-bar-container">
                            <div className="progress-bar" style={{ width: `${(stats.mediumSolved / stats.totalMedium) * 100}%` }}></div>
                            <div className="progress-label">
                                {stats.mediumSolved} / {stats.totalMedium}
                            </div>
                        </div>
                    </div>


                    <div className="hard solved">
                        <label>Hard</label>
                        <div className="progress-bar-container">
                            <div className="progress-bar" style={{ width: `${(stats.hardSolved / stats.totalHard) * 100}%` }}></div>
                            <div className="progress-label">
                                {stats.hardSolved} / {stats.totalHard}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default LeetCodeStatistics;
