import React from 'react';
import './Experience.css';

function Experience() {
  return (
    <section id="experience">
      <ul>
        <li>
          <div className="duration">May 2024 - Jul 2024</div>
          <div className="exp-details">
            <span>Data Analyst Intern - Otsuka</span>
            <p>Built RESTful APIs to migrate pharma data from legacy system to cloud based solution Atlan</p>
          </div>
        </li>
        <li>
          <div className="duration">Dec 2022 - Oct 2023</div>
          <div className="exp-details">
            <span>Senior Data Analyst - ZS Associates</span>
            <p>Built and maintained data catalog that housed metadata of 53 TB pharma metadata. Worked closely with cross-functional teams to implement best practices of a data catalog.
            Streamlined project planning activities, estimated development efforts, and tracked project milestones.
            </p>
          </div>
        </li>
        <li>
          <div className="duration">Jan 2020 - Nov 2023</div>
          <div className="exp-details">
            <span>Data Analyst - ZS Associates</span>
            <p>Developed end-to-end ETL processes for data extraction, transformation, and loading, ensuring efficient dataflow from 60+ data sources to AWS Redshift reporting database.
            Wrote complex SQL queries, scripts, and stored procedures to extract, manipulate, and load data, optimizingquery performance for large data sets
            </p>
          </div>
        </li>
      </ul>
    </section>
  );
}

export default Experience;
